import React, { Component } from 'react';
import introLogo from './Logo-300x277.png';
import bgLogo from './bg_logo.png';
import Paper from '@material-ui/core/Paper';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  green,
  amber,
} from '@material-ui/core/colors';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';


const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: amber,
  },
});


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  };

  componentDidMount() {
    this.showIntro();
  }

  showIntro() {
    this.setState({
      loading: true,
    });

    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //   })
    // }, 2000);
  }

  render() {
    const {
      loading,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Box  alignItems="center">
          
          <Paper elevation={3} style={{
            display: 'inline-block',
            position: 'relative',
            top: 20,
            padding: '50px 50px 50px 50px',
            // margin: 20,
            maxWidth: '800px',
            width: '80%',
            backgroundColor: 'rgba(250,250,250,0.6)',
            // width: '80%',
            backgroundImage: 'url(https://www.maigesellschaft-stommeln.de/wp-content/uploads/2020/03/lgog_lion.png), url(https://www.maigesellschaft-stommeln.de/wp-content/uploads/2020/03/lgog_tree-5.png)',
            backgroundSize: 'contain',
            backgroundPosition: 'right bottom, left bottom',
            backgroundRepeat: 'no-repeat',
            // backgroundAttachment: 'fixed',
          }}
            variant='outlined'
          >
            <div style={{
              'fontWeight': '400',
              'fontSize': '2rem',
              'marginBottom': '10px',
            }}>Nikolausversammlung<br/>Maigesellschaft Stommeln<br/><span style={{ fontSize: '1rem' }} >„BIRKE“ 1947 e.V.</span></div>
            <div></div>
            
          </Paper>

          { loading && (
            <div id="loader">
              <img src={introLogo} className="gen-animation" id="logo-loader" alt="intro" />
            </div>
          )}

          <Paper
            variant='outlined'
            style={{
            // position: 'absolute',
            // bottom: 50,
            display: 'inline-block',
            padding: 20,
            marginBottom: 100,
            marginTop: '5%',
            maxWidth: '800px',
            width: '80%',
            fontSize: '1.5rem',
            backgroundColor: 'rgba(250,250,250,0.6)',
          }}>
            Auf dieser Seite findest Du zeitnah vor der Versammlung die Zugangsdaten zum Zoom-Meeting.
            <br/><br/>

            Wir freuen uns auf Dich und starten am <b>04.12.</b> um <b>20:00</b> Uhr.
          </Paper>

          <div>
          
            <Button
        variant="outlined"
        color="primary"
        size="large"
        startIcon={<LiveHelpIcon />}
        style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 2,
            }}
      >
        <a href="https://support.zoom.us/hc/de/articles/201362193-An-einem-Meeting-teilnehmen" target="_blank" rel="noopener noreferrer">Zoom Software installieren</a>
      </Button>
    
          </div>
            </Box>
        </div>
      </ThemeProvider>
    );
  }
}
